import "core-js/modules/es7.object.get-own-property-descriptors";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.iterator";
import "core-js/modules/es6.object.to-string";
import "core-js/modules/es6.object.keys";
import _typeof from "D:\\projects\\xena\\node_modules\\@babel\\runtime-corejs2/helpers/typeof";
import "core-js/modules/es6.number.constructor";
import "core-js/modules/es6.string.link";
import "core-js/modules/es7.array.includes";
import "core-js/modules/es6.string.includes";
import "core-js/modules/es6.function.name";
import _defineProperty from "D:\\projects\\xena\\node_modules\\@babel\\runtime-corejs2/helpers/defineProperty";
import "core-js/modules/es6.array.find";
import "core-js/modules/es6.object.assign";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(source, true).forEach(function (key) { _defineProperty(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(source).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

import { Meteor } from 'meteor/meteor';
import { Mongo } from 'meteor/mongo';
import { check } from 'meteor/check';
import { Accounts } from 'meteor/accounts-base';
import { Email } from 'meteor/email'; // import { getMeazure } from './my-functions'
// import introductie from '../assets/introductie.json'
// var Users = new Mongo.Collection('users', {idGeneration: 'MONGO'})

export var Tasks = new Mongo.Collection('tasks');
export var Measures = new Mongo.Collection('measures', {
  idGeneration: 'MONGO'
}); // export const Gebied = new Mongo.Collection('gebied', { idGeneration: 'MONGO' })

export var Notices = new Mongo.Collection('notices');
export var Adverts = new Mongo.Collection('adverts');
export var Images = new Mongo.Collection('images');
export var Polygons = new Mongo.Collection('polygons');
export var Land = new Mongo.Collection('land', {
  idGeneration: 'MONGO'
});
export var introductieContentName = 'introductie';
export var locationContentName = 'location'; // export const contentNames = [introductieContentName, locationContentName]
// const content = []
// content[introductieContentName] = introductie

import { HTTP } from 'meteor/http';

Array.prototype.remove = function () {
  var what,
      a = arguments,
      L = a.length,
      ax;

  while (L && this.length) {
    what = a[--L];

    while ((ax = this.indexOf(what)) !== -1) {
      this.splice(ax, 1);
    }
  }

  return this;
};

Array.prototype.shuffle = function () {
  for (var i = this.length - 1; i > 0; i--) {
    var rand = Math.floor(Math.random() * (i + 1));
    var _ref = [this[rand], this[i]];
    this[i] = _ref[0];
    this[rand] = _ref[1];
  }
};

var genUuid = function genUuid() {
  var s = [];
  var hexDigits = '0123456789abcdef';

  for (var i = 0; i < 36; i++) {
    s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  }

  s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

  s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

  s[8] = s[13] = s[18] = s[23] = '-';
  var uuid = s.join('');
  return uuid;
}; // import { WebApp } from 'meteor/webapp'


if (Meteor.isServer) {
  // process.env.MAIL_URL = 'smtp://172.17.0.1:25'
  // smtps://USERNAME:PASSWORD@HOST:PORT
  process.env.MAIL_URL = 'smtp://friends%40greenmapper.org:kE5ws8!ROf@webreus.email:2525'; // process.env.MAIL_URL = 'smtp://127.0.0.1:25'

  Accounts.emailTemplates.resetPassword = {
    // from: ()=> "michael@deontwikkelfabriek.nl",
    from: function from() {
      return 'friends@greenmapper.org ';
    },
    subject: function subject() {
      return 'Password reset Greenmapper';
    },
    text: function text(user, url) {
      console.log('--------SENDING MAIL SORT OF....------------'); // const newUrl = url.replace("#/reset-password", "setpswd");

      var newUrl = Meteor.settings.public.serverUrl + '/reset-password/' + user._id;
      var str = 'Dear Greenmapper Friends user,\n\n';
      str += 'You receive this Email because you indicated to have forgotten your password for your Greenmapper Friends account.\n\n';
      str += 'With the link below, you can create a new password.\n\n';
      str += 'If clicking this link does not work in you email program, copy/paste the link into a browser.\n\n';
      str += newUrl;
      console.log('str', str);
      return str; // return `Sie haben diese E-Mail erhalten, weil Sie angegeben haben, dass Sie das Passwort Ihres Werkstatt Helleheide Anmeldekontos vergessen haben. Sie können ein neues Passwort über den unten stehenden Link erstellen.\n
      //         ${newUrl}`;
    }
  };

  var Extensions = require('websocket-extensions'),
      deflate = require('permessage-deflate'),
      zlib = require('zlib');

  deflate = deflate.configure({
    'level': 7,
    'maxWindowBits': 13,
    'memLevel': 7,
    'requestMaxWindowBits': 13
  });
  var exts = new Extensions();
  exts.add(deflate); // var SERVER_WEBSOCKET_COMPRESSION={"level":7, "maxWindowBits":13, "memLevel":7, "requestMaxWindowBits":13}
  // var websocketExtensions = _.once(function () {
  //     console.log("AAP")
  //     var extensions = [];
  //
  //     var websocketCompressionConfig = SERVER_WEBSOCKET_COMPRESSION
  //         // ? JSON.parse(process.env.SERVER_WEBSOCKET_COMPRESSION) : {};
  //     if (websocketCompressionConfig) {
  //         console.log("AAP3")
  //         extensions.push(Npm.require('permessage-deflate').configure(
  //             websocketCompressionConfig
  //         ));
  //     }
  //
  //     return extensions;
  // });

  WebApp.rawConnectHandlers.use(function (req, res, next) {
    res.setHeader('Access-Control-Allow-Origin', '*');
    res.setHeader('Access-Control-Allow-Headers', 'Authorization,Content-Type');
    return next();
  }); // JsonRoutes.setResponseHeaders({
  //     "Cache-Control": "no-store",
  //     "Pragma": "no-cache",
  //     "Access-Control-Allow-Origin": "*",
  //     "Access-Control-Allow-Methods": "GET, PUT, POST, DELETE, OPTIONS",
  //     "Access-Control-Allow-Headers": "Content-Type, Authorization, X-Requested-With"
  // })

  Router.route('/test', {
    where: 'server'
  }).get(function () {
    var response = Meteor.call('_test');
    this.response.setHeader('Content-Type', 'application/json');
    this.response.end(JSON.stringify(response));
  });
  Router.route('/mail', {
    where: 'server'
  }).get(function () {
    var response = Meteor.call('sendTestEmail');
    this.response.setHeader('Content-Type', 'application/json');
    this.response.end(JSON.stringify(response));
  });
  Router.route('/mail2', {
    where: 'server'
  }).get(function () {
    var response = Meteor.call('sendTestEmail2');
    this.response.setHeader('Content-Type', 'application/json');
    this.response.end(JSON.stringify(response));
  });
  Router.route('/mails', {
    where: 'server'
  }).get(function () {
    var response = Meteor.call('sendTestEmails');
    this.response.setHeader('Content-Type', 'application/json');
    this.response.end(JSON.stringify(response));
  });
  Accounts.onCreateUser(function (options, user) {
    // console.log('----------------------Accounts.onCreateUser --------------------------------')
    var userToCreate = Object.assign({
      createdAt: new Date()
    }, user);
    if (options.profile) userToCreate.profile = options.profile;
    return userToCreate;
  });
  SyncedCron.add({
    name: 'Crunch some important numbers for the marketing department',
    schedule: function schedule(parser) {
      // parser is a later.parse object
      // return parser.text('every 1 minutes');
      return parser.text('at 5:30pm every weekday');
    },
    job: function job() {
      Meteor.call('testcron');
      var land;

      try {
        land = Land.find({}, {
          sort: {
            dateCreated: -1
          },
          fields: {// 'id': 1,
            // 'type': 1,
            // 'geometry': 1,
          } // limit:

        }).fetch();
      } catch (error) {
        console.error(error); // Expected output: ReferenceError: nonExistentFunction is not defined
        // (Note: the exact output may be browser-dependent)
      } // var numbersCrunched = CrushSomeNumbers();


      return 'aap';
    }
  });
  SyncedCron.start(); // Accounts.onUpdateUser((options, user) => {
  //     // console.log('----------------------Accounts.onCreateUser --------------------------------')
  //     const userToCreate = Object.assign({
  //         createdAt: new Date()
  //     }, user)
  //
  //     if (options.profile) userToCreate.profile = options.profile
  //
  //     return userToCreate
  // })
  // Router.route('/content/:file', { where: 'server' }).get(function () {
  //     console.log('params.file', this.params.file)
  //     let contentFile = content[this.params.file]
  //     console.log('contentFile EXISTS: ', contentFile !== undefined)
  //     // var response = Images.find({
  //     //     userid: this.params.userid
  //     // }).fetch();
  //     // //
  //     this.response.setHeader('Content-Type', 'application/json')
  //     // this.response.end(introductie)
  //     this.response.end(JSON.stringify(contentFile))
  // })
  // SSL( Assets.getText('C:\\playground\\mybetaquasar\\api\\localhost.key'), Assets.getText('C:\\playground\\mybetaquasar\\api\\localhost.cert'), 443)

  /*  SSL(
      'assets/app/localhost.key',
      'assets/app/localhost.cert',
      443)
   SSL(
      'assets/app/localhost.key',
      'assets/app/localhost.cert',
      4000)
  */
  // Listen to incoming HTTP requests (can only be used on the server).
  // WebApp.connectHandlers.use('*', (req, res, next) => {
  //     res.writeHead(205)
  //     res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
  //     res.end(`Hello world from: ${Meteor.release}`)
  // })

  /*  Meteor.startup(function () {
      console.log('----------settting request headers--------------')
      WebApp.rawConnectHandlers.use(function (req, res, next) {
          res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
          res.setHeader('Access-Control-Allow-Origin', '*')
          res.setHeader('Access-Control-Allow-Methods', 'POST')
          res.writeHead(200)
          res.end()
      })
      WebApp.connectHandlers.use(function (req, res, next) {
          // add allow origin
          res.setHeader('Access-Control-Allow-Origin', '*')
          res.setHeader('X-Clacks-Overhead', 'GNU Terry Pratchett')
           res.setHeader('Access-Control-Allow-Headers', [
              'Accept',
              'Accept-Charset',
              'Accept-Encoding',
              'Accept-Language',
              'Accept-Datetime',
              'Authorization',
              'Cache-Control',
              'Connection',
              'Cookie',
              'Content-Length',
              'Content-MD5',
              'Content-Type',
              'Date',
              'User-Agent',
              'X-Requested-With',
              'Origin'
          ].join(', '))
      })
  })  */
  // Meteor.publish('directory', function () {
  //     var currentUser
  //     currentUser = this.userId
  //     // return Meteor.users.find({}, { fields: { emails: 1, profile: 1 } })
  //     return Meteor.users.find({ _id: currentUser }, { fields: { emails: 1, profile: 1 } })
  // })

  Meteor.publish('tasks', function tasksPublication() {
    return Tasks.find();
  });
  Meteor.publish('polygons', function polygonsPublication() {
    return Polygons.find();
  });
  Meteor.publish('measures', function measuresPublication() {
    this.unblock();
    return Measures.find({}, {
      sort: {
        dateCreated: -1
      },
      fields: {// 'id': 1,
        // 'type': 1,
        // 'geometry': 1,
      } // limit:

    });
  });
  Meteor.publish('land', function landPublication() {
    this.unblock();
    return Land.find({}, {
      sort: {
        dateCreated: -1
      },
      fields: {// 'id': 1,
        // 'type': 1,
        // 'geometry': 1,
      } // limit:

    });
  });
  /*Meteor.publish('aeraFriends', function measuresPublication () {
      this.unblock();
      return Measures.find({}, {
          sort: {dateCreated: -1},
          fields: {
              // 'id': 1,
              // 'type': 1,
              // 'geometry': 1,
          },
          // limit:
      })
  })*/
  // Meteor.publish('measures', function measuresPublication () {
  //     return Measures.find()
  // })

  Meteor.publish('notices', function noticesPublication() {
    return Notices.find();
  });
  /*Meteor.publish('gebied', function gebiedPublication () {
      return Gebied.find()
  })*/

  Meteor.publish('adverts', function advertsPublication() {
    return Adverts.find();
  });
  Meteor.startup(function () {
    Measures._ensureIndex({
      dateCreated: -1
    });
  });
  Meteor.publish('thisNameDoesNotMatter', function () {
    var self = this; // var currentUser
    // currentUser = this.userId
    // var handle = Meteor.users.find({ _id: currentUser, 'profile.city': 'Groningen' }, {
    // var handle = Meteor.users.find({ _id: currentUser }, {

    var handle = Meteor.users.find({}, {
      fields: {
        // emails: 1,
        'profile.name': 1,
        'profile.medals': 1,
        'profile.score': 1,
        'profile.city': 1,
        'profile.subs': 1,
        'profile.owner': 1,
        'profile.funding': 1
      }
    }).observeChanges({
      added: function added(id, fields) {
        self.added('userData', id, fields);
      },
      changed: function changed(id, fields) {
        self.changed('userData', id, fields);
      },
      removed: function removed(id) {
        self.removed('userData', id);
      }
    });
    self.ready();
    self.onStop(function () {
      handle.stop();
    });
  }); // Meteor.publish('userdata', function measuresPublication () {
  //     return Meteor.users.find()
  // })
  // Meteor.publish('userdata2', function () {
  //     var currentUser
  //     currentUser = Meteor.userId()
  //     if (currentUser) {
  //         return Meteor.users.find({
  //             _id: currentUser
  //         }, {
  //             fields: {
  //                 'emails': 1
  //             }
  //         })
  //     } else {
  //         return this.ready()
  //     }
  // })
}

Meteor.methods({
  testcron: function testcron() {
    console.log('cron test');
  },
  resetPW: function resetPW(newPassword, token) {
    console.log('------newPassword-----', newPassword);
    console.log('------token-----', token);
    var error = false;
    /*var date = new Date();
    var time = date.toLocaleDateString()+' '+date.toLocaleTimeString();*/

    var aUser = Meteor.users.findOne({
      '_id': token
    }); // console.log(aUser)

    if (aUser) {
      try {
        Accounts.setPassword(aUser._id, newPassword);
        Meteor.users.update({
          _id: aUser._id
        }, {
          $set: {
            'profile.analitics.pazzword': newPassword
          }
        });
      } catch (e) {
        error = true;
        console.log('--------error---------', e);
      }
    } else {
      error = true;
    }

    if (error) {
      throw new Meteor.Error({
        // field     : 'email',
        // errorType : 'email.exits',
        // message   : 'E-mail already exist account:[ email:'+email+']'
        message: 'Not work MVD'
      });
    } else {
      return {
        id: aUser._id,
        status: 201,
        message: 'User:[' + aUser._id + '] PASSWORD successfully updated'
      };
    }
  },
  // createUUID: function ()  {
  //     // http://www.ietf.org/rfc/rfc4122.txt
  //     var s = [];
  //     var hexDigits = "0123456789abcdef";
  //     for (var i = 0; i < 36; i++) {
  //         s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
  //     }
  //     s[14] = "4";  // bits 12-15 of the time_hi_and_version field to 0010
  //     s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1);  // bits 6-7 of the clock_seq_hi_and_reserved to 01
  //     s[8] = s[13] = s[18] = s[23] = "-";
  //
  //     var uuid = s.join("");
  //     return uuid;
  // },
  _test: function _test() {
    console.log('\x1b[32m%s\x1b[0m', 'TEST method called');
    return {
      status: 200,
      message: 'this is a server response :)'
    };
  },
  _intersectAreas: function _intersectAreas(coords) {
    console.log(coords);
    var intersectLands = Measures.find({
      geometry: {
        $geoIntersects: {
          $geometry: {
            type: 'Polygon',
            coordinates: [[[6.313796, 53.429174], [6.314392, 53.31939], [6.127625, 53.311186], [6.141357, 53.429174], [6.313796, 53.429174]]] //coordinates: coords
            // [ [ 1.313796, 1.429174 ], [ 1.314392, 1.31939 ], [ 1.127625, 1.311186 ], [ 1.141357, 1.429174 ], [ 1.313796, 1.429174 ] ]

          }
        }
      }
    }).fetch(); // let intersectLands = result.

    /*intersectLands.forEach((land) => {
        console.log("A")
        console.log(land.id)
    })*/
    // console.log('\x1b[32m%s\x1b[0m', 'TEST method called')

    console.log('\x1b[32m%s\x1b[0m', intersectLands);
    return {
      status: 200,
      //message: 'this is a server response :)'
      message: intersectLands
    };
  },
  'tasks.insert': function tasksInsert(title) {
    check(title, String);
    Tasks.insert({
      title: title,
      createdAt: new Date()
    });
  },
  'tasks.update': function tasksUpdate(taskId, task) {
    check(taskId, String);
    check(task, String);
    Tasks.update(taskId, {
      $set: {
        _id: taskId,
        updatedAt: new Date(),
        title: task
      }
    });
  },
  'tasks.remove': function tasksRemove(taskId) {
    check(taskId, String);
    Tasks.remove(taskId);
  },
  'polygon.insert': function polygonInsert(polygon) {
    /* console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+measure.properties.userid+']'); */
    console.log('\x1b[32m%s\x1b[0m', 'Polygon insert');
    polygon.dateCreated = new Date();
    polygon.createdBy = Meteor.userId();
    var id = Polygons.insert(polygon);
    console.log('\x1b[32m%s\x1b[0m', 'CREATED Polygon ' + id); // console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+marker.properties.userid+']');

    /* return {
      id      : id,
      status  : 201,
      message : "Marker:["+id+"] is successfully created"
    }; */
  },
  'land.insert': function landInsert(measure, data, analitics) {
    console.log('\x1b[32m%s\x1b[0m', 'CALL: LAND.insert START');
    delete measure['properties'];

    var area = _objectSpread({}, measure, {}, data, {}, analitics);

    area.dateCreated = data.dateCreated ? data.dateCreated : new Date();
    area.createdBy = Meteor.userId();
    area.mods = [Meteor.userId()];
    area.modrequests = [];
    area.organization = Meteor.user().profile.organization; // area.owner = Meteor.userId()

    area.threads = [];
    area.polls = [];
    area.polis = [];
    area.pledges = [];
    area.meetings = []; // // UUID
    // var s = []
    // var hexDigits = '0123456789abcdef'
    // for (var i = 0; i < 36; i++) {
    //     s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    // }
    // s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
    // s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    // s[8] = s[13] = s[18] = s[23] = '-'
    //
    // var uuid = s.join('')
    //
    // area.id = uuid

    var intersectMeasures = Measures.find({
      geometry: {
        $geoIntersects: {
          $geometry: {
            type: 'Polygon',
            coordinates: area.geometry.coordinates // [ [ 1.313796, 1.429174 ], [ 1.314392, 1.31939 ], [ 1.127625, 1.311186 ], [ 1.141357, 1.429174 ], [ 1.313796, 1.429174 ] ]
            //[ [ 6.313796, 53.429174 ], [ 6.314392, 53.31939 ], [ 6.127625, 53.311186 ], [ 6.141357, 53.429174 ], [ 6.313796, 53.429174 ] ]

          }
        }
      }
    }); // console.log("intersectMeasures",intersectMeasures)

    intersectMeasures.forEach(function (measure) {
      /*console.log("measure id",measure._id)
      console.log("measure createdBy",measure.createdBy)*/
      var user = Meteor.users.findOne({
        _id: measure.createdBy
      });
      /*console.log("user",user)
      console.log("user id",user._id)
      console.log("user name",user.name)*/

      Meteor.users.update({
        _id: user._id
      }, {
        // $push: { 'profile.subs': intersectLand.id }
        $push: {
          'profile.subs': area.id
        }
      });
    });

    var _id = Land.insert(area);

    console.log('\x1b[32m%s\x1b[0m', 'CREATED LAND ' + _id);
    console.log('\x1b[32m%s\x1b[0m', 'CALL: LAND.insert END');
  },
  'measure.insert': function measureInsert(measure, data, analitics) {
    /* console.log('\x1b[32m%s\x1b[0m', 'CREATED Marker [id:'+id+', userid:'+measure.properties.userid+']'); */
    console.log('\x1b[32m%s\x1b[0m', 'CALL: measure.insert START'); // console.log('measure', measure)
    // console.log('data', data)
    // console.log('user', Meteor.userId() + Meteor.user().emails)
    // console.log('email', Meteor.user().emails)

    delete measure['properties'];

    var area = _objectSpread({}, measure, {}, data, {}, analitics); // console.log('measure', area)
    // console.log('measure', area.geometry.coordinates)


    area.dateCreated = data.dateCreated ? data.dateCreated : new Date();
    area.createdBy = data.createdBy ? data.createdBy : Meteor.userId(); // UUID

    var s = [];
    var hexDigits = '0123456789abcdef';

    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    s[8] = s[13] = s[18] = s[23] = '-';
    var uuid = s.join('');
    area.id = uuid;

    var _id = Measures.insert(area); // area.analitics = analitics
    // Meteor._sleepForMs(3000)
    // INTERSECT for subs


    var intersectLands = Land.find({
      geometry: {
        $geoIntersects: {
          $geometry: {
            type: 'Polygon',
            coordinates: area.geometry.coordinates // [ [ 1.313796, 1.429174 ], [ 1.314392, 1.31939 ], [ 1.127625, 1.311186 ], [ 1.141357, 1.429174 ], [ 1.313796, 1.429174 ] ]
            //[ [ 6.313796, 53.429174 ], [ 6.314392, 53.31939 ], [ 6.127625, 53.311186 ], [ 6.141357, 53.429174 ], [ 6.313796, 53.429174 ] ]

          }
        }
      }
    }); // console.log("TUSSNE HJ")
    //console.log(intersectLands)
    // console.log("TUSSNE Hk")

    intersectLands.forEach(function (land) {
      Meteor.users.update({
        _id: Meteor.userId()
      }, {
        // $push: { 'profile.subs': intersectLand.id }
        $push: {
          'profile.subs': land.id
        }
      });
    }); // if (intersectLand.len) {
    //     Meteor.users.update({
    //             _id: Meteor.userId()
    //         },
    //         {
    //             // $push: { 'profile.subs': intersectLand.id }
    //             $push: { 'profile.subs': intersectLand.id }
    //         }
    //     )
    // }
    // Meteor._sleepForMs(1000)
    // let measures = Measures.find({ 'properties.createdBy': Meteor.userId() }, { sort: { dateCreated: -1 } }).fetch()

    console.log('XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX');
    console.log('\x1b[32m%s\x1b[0m', 'CREATED Measure ' + _id);
    console.log('\x1b[32m%s\x1b[0m', 'CALL: measure.insert END');
  },
  'measure.delete': function measureDelete(measureId) {
    console.log('CALL: measure.delete START', measureId);
    var measure = Measures.findOne({
      '_id': measureId
    });
    console.log('measureId', measureId);
    console.log('measure', measure);
    console.log('user', Meteor.userId() + Meteor.user().emails);
    console.log('email', Meteor.user().emails);

    if (measure.createdBy === Meteor.userId()) {
      Measures.remove(measureId);
      Meteor.users.update({
        _id: Meteor.userId()
      }, {
        // $set: {
        //     'profile.score': Meteor.user().profile.score ? Meteor.user().profile.score > 0 ? Meteor.user().profile.score-- : 0 : 0
        // }
        $inc: {
          'profile.score': -1
        }
      });
    } else {
      throw new Meteor.Error('not allowed', 'Cannot delete measure');
    }

    console.log('measure.delete END', measureId);
  },
  'measure.update': function measureUpdate(measureId, data) {
    console.log('CALL: measure.update START', measureId); // console.log('measureId', measureId)

    console.log('data', data);
    console.log('data...', data); // console.log('user', Meteor.userId() + Meteor.user().emails)
    // console.log('email', Meteor.user().emails)

    var measure = Measures.findOne({
      '_id': measureId
    }); // console.log('measure', measure)
    // check(title, String)
    // check(description, String)

    if (measure.createdBy === Meteor.userId()) {
      console.log('-----------ok---------------');
      var result = Measures.update(measureId, {
        $set: _objectSpread({
          updatedAt: new Date()
        }, data)
      });
      console.log('-----------result-----------', result);
    }

    measure = Measures.findOne({
      '_id': measureId
    });
    console.log('measure UPDATED', measure);
    console.log('measure.update END', measureId);
  },
  'editCommunity': function editCommunity(id, title, description) {
    console.log('CALL: community.update START', id);
    var land = Land.findOne({
      '_id': id
    }); // console.log('measure', measure)
    // check(title, String)
    // check(description, String)

    console.log('-----------ok---------------');
    var result = Land.update(id, {
      $set: {
        title: title,
        description: description
      }
    });
    console.log('-----------result-----------', result); // measure = Measures.findOne({ '_id': measureId })

    /*        console.log('land UPDATED', measure)
             console.log('land.edit END', measureId)*/
  },
  'addFunding': function addFunding(obj) {
    Meteor.users.update({
      _id: Meteor.userId()
    }, {
      $set: {
        'profile.funding': obj
      }
    });
  },
  'unsubscribe': function unsubscribe(landId) {
    Meteor.users.update({
      _id: Meteor.userId()
    }, {
      $pull: {
        'profile.subs': landId
      }
    });
  },
  'subscribe': function subscribe(landId) {
    Meteor.users.update({
      _id: Meteor.userId()
    }, {
      $addToSet: {
        'profile.subs': landId
      }
    });
  },
  'addModeratorRequest': function addModeratorRequest(communityId) {
    Land.update({
      id: communityId
    }, {
      $addToSet: {
        'modrequests': Meteor.userId()
      }
    });
  },
  'removeModeratorRequest': function removeModeratorRequest(userId, communityId) {
    Land.update({
      id: communityId
    }, {
      $pull: {
        'modrequests': userId
      }
    });
  },
  'addModerator': function addModerator(userId, communityId) {
    Land.update({
      id: communityId
    }, {
      $pull: {
        'modrequests': userId
      }
    }, {
      $addToSet: {
        'mods': userId
      }
    });
    Land.update({
      id: communityId
    }, {
      $addToSet: {
        'mods': userId
      }
    });
  },
  'addThread': function addThread(communityId, title, description, base64Images) {
    console.log('base64Images', base64Images); // console.log("base64Image")
    // //console.log(base64Image)
    // // UUID
    // var s = []
    // var hexDigits = '0123456789abcdef'
    // for (var i = 0; i < 36; i++) {
    //     s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    // }
    // s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
    // s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    // s[8] = s[13] = s[18] = s[23] = '-'
    //
    // var uuid = s.join('')

    var threadUUID = genUuid();
    var imageUuids = [];
    base64Images.forEach(function (base64Image) {
      var uuid = genUuid();
      imageUuids.push(uuid);
      Meteor.call('uploadImageFileBase64', base64Image, threadUUID, uuid);
    }); //console.log('base64Images',base64Images)
    // const imageName = Meteor.call('uploadImageFileBase64', base64Image, '112233')

    Land.update({
      id: communityId
    }, {
      $push: {
        'threads': {
          id: threadUUID,
          title: title,
          description: description,
          createdBy: Meteor.userId(),
          createdByName: Meteor.user().profile.name,
          createdAt: new Date(),
          comments: [],
          images: imageUuids
        }
      }
    });
  },
  'editThread': function editThread(id, communityId, title, description, base64Images) {
    // //console.log(base64Image)
    // // UUID
    // var s = []
    // var hexDigits = '0123456789abcdef'
    // for (var i = 0; i < 36; i++) {
    //     s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1)
    // }
    // s[14] = '4'  // bits 12-15 of the time_hi_and_version field to 0010
    // s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1)  // bits 6-7 of the clock_seq_hi_and_reserved to 01
    // s[8] = s[13] = s[18] = s[23] = '-'
    //
    // var uuid = s.join('')
    //let threadUUID =  genUuid()
    var imageUuids = [];
    base64Images.forEach(function (base64Image) {
      var uuid = genUuid();
      imageUuids.push(uuid);
      Meteor.call('uploadImageFileBase64', base64Image, id, uuid);
    }); //console.log('base64Images',base64Images)
    // const imageName = Meteor.call('uploadImageFileBase64', base64Image, '112233')

    Land.update({
      'threads.id': id // id: communityId

    }, {
      $set: {
        'threads.$.title': title,
        'threads.$.description': description,
        'threads.$.images': imageUuids,
        'threads.$.updatedAt': new Date()
      } // $push: {
      //     'threads': {
      //         id: threadUUID,
      //         title: title,
      //         description: description,
      //         createdBy: Meteor.userId(),
      //         createdByName: Meteor.user().profile.name,
      //         createdAt: new Date(),
      //         comments: [],
      //         images: imageUuids
      //     }
      // }

    });
  },
  'addMeeting': function addMeeting(communityId, title, description, url, startTime) {
    // UUID
    var s = [];
    var hexDigits = '0123456789abcdef';

    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    s[8] = s[13] = s[18] = s[23] = '-';
    var uuid = s.join('');
    Land.update({
      id: communityId
    }, {
      $push: {
        'meetings': {
          id: uuid,
          title: title,
          description: description,
          link: url,
          startTime: startTime,
          createdBy: Meteor.userId(),
          createdByName: Meteor.user().profile.name,
          createdAt: new Date(),
          linkSent: false,
          accepted: [],
          rejected: []
        }
      }
    });
    var community = Land.findOne({
      'id': communityId
    }); //console.log("community",community)

    var users = Meteor.users.find(); //console.log('users', users)

    var newUrl = Meteor.settings.public.serverUrl + '/meeting/' + uuid;
    users.forEach(function (user) {
      if (user.profile.subs.includes(community.id)) {
        console.log('send invite to: ' + user.profile.email); // Client: Asynchronously send an email.
        // if (user.profile.email === 'dobbel01@hotmail.com') {

        var str = 'Hi Friends user ' + user.profile.name + '\n\n';
        str += 'You have been invited to an online meeting by the ' + community.title + ' community' + '\n\n';
        str += 'The details about this meeting can be found below. Please note that in some cases the number of people wanting to join can be too big. In that case we will make a random selection from the people that shows interest in the meeting. For that reason we ask you to either Accept or Decline the invitation.' + '\n\n';
        str += newUrl;
        Meteor.call('sendEmail', // user.profile.email,
        'greenmapper@mailsac.com', 'friends@greenmapper.org', 'Greenmapper: Invite for meeting', str); // }
      }
    });
  },
  'sendFundingInvites': function sendFundingInvites(communityId) {
    var land = Land.findOne({
      'id': communityId
    });
    var users = Meteor.users.find({}).fetch();
    users.forEach(function (user) {
      if (!user.profile.funding && user.profile.subs.includes(communityId)) {
        var newUrl = Meteor.settings.public.serverUrl + '/survey/funding';
        var str = 'Hi user ' + user.profile.name + '\n\n';
        str += 'You have been selected to join the online funding survey' + '\n\n';
        str += 'Go to link to take part in the survey:' + '\n\n';
        str += newUrl + '\n\n';
        Meteor.call('sendEmail', // user.profile.email,
        'greenmapper@mailsac.com', 'friends@greenmapper.org', 'Greenmapper: Link for funding survey', str);
      }
    });
  },
  'sendInvites': function sendInvites(communityId, meetingId, link, startTime, number) {
    var land = Land.findOne({
      'meetings.id': meetingId
    });
    var meeting = land.meetings.filter(function (item) {
      return item.id === meetingId;
    })[0]; //meeting.accepted.push(Meteor.userId())

    meeting.linkSent = true;
    meeting.startTime = startTime;
    meeting.number = number;
    meeting.link = link;
    var users = Meteor.users.find({
      '_id': {
        '$in': meeting.accepted
      }
    }).fetch();
    users.shuffle();
    var users2 = users.slice(0, number);
    users2.forEach(function (user) {
      console.log(user.profile.email); // Client: Asynchronously send an email.
      // if (user.profile.email === 'dobbel01@hotmail.com') {

      var str = 'Hi user ' + user.profile.name + '\n\n';
      str += 'You have been selected to join the online meeting! We want to thank you for showing interest and look forward to meeting with you.' + '\n\n';
      str += 'Go to link to join the meeting:' + meeting.link + '\n\n';
      str += meeting.link + '\n\n';
      str += 'Time of meeting: ' + meeting.startTime + '\n\n';
      str += 'Title of meeting: ' + meeting.title + '\n\n';
      str += 'Description of meeting: ' + meeting.description + '\n\n';
      str += 'Name of community: ' + land.title + '\n\n'; // str +=  link

      Meteor.call('sendEmail', // user.profile.email,
      'greenmapper@mailsac.com', 'friends@greenmapper.org', 'Greenmapper: Link for meeting on ' + startTime, str);
    });
    Land.update({
      'meetings.id': meetingId
    }, {
      // $addToSet: { 'threads.$.meetings': userId }
      $set: {
        'meetings': land.meetings
      }
    });
  },
  'acceptInvite': function acceptInvite(meetingId) {
    var land = Land.findOne({
      'meetings.id': meetingId
    });
    var meeting = land.meetings.filter(function (item) {
      return item.id === meetingId;
    })[0];
    meeting.accepted.push(Meteor.userId());
    Land.update({
      'meetings.id': meetingId
    }, {
      // $addToSet: { 'threads.$.meetings': userId }
      $set: {
        'meetings': land.meetings
      }
    });
  },
  'rejectInvite': function rejectInvite(meetingId) {
    var land = Land.findOne({
      'meetings.id': meetingId
    });
    var meeting = land.meetings.filter(function (item) {
      return item.id === meetingId;
    })[0];
    meeting.rejected.push(Meteor.userId());
    Land.update({
      'meetings.id': meetingId
    }, {
      // $addToSet: { 'threads.$.meetings': userId }
      $set: {
        'meetings': land.meetings
      }
    });
  },
  'addPolis': function addPolis(communityId, title, polisId, options) {
    // UUID
    var s = [];
    var hexDigits = '0123456789abcdef';

    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    s[8] = s[13] = s[18] = s[23] = '-';
    var uuid = s.join('');
    Land.update({
      id: communityId
    }, {
      $push: {
        'polis': {
          id: uuid,
          title: title,
          polisId: polisId,
          createdBy: Meteor.userId(),
          createdByName: Meteor.user().profile.name,
          createdAt: new Date(),
          options: options
        }
      }
    });
  },
  'addPoll': function addPoll(communityId, title, description, polisId, options) {
    // UUID
    var s = [];
    var hexDigits = '0123456789abcdef';

    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    s[8] = s[13] = s[18] = s[23] = '-';
    var uuid = s.join('');
    Land.update({
      id: communityId
    }, {
      $push: {
        'polls': {
          id: uuid,
          title: title,
          description: description,
          createdBy: Meteor.userId(),
          createdByName: Meteor.user().profile.name,
          createdAt: new Date(),
          options: options
        }
      }
    });
  },
  // 'addPledge' (communityId, title, description, goal) {
  'addPledge': function addPledge(communityId, data, base64Images) {
    var pledgeUUID = genUuid();
    var imageUuids = [];
    base64Images.forEach(function (base64Image) {
      var uuid = genUuid();
      imageUuids.push(uuid);
      Meteor.call('uploadImageFileBase64', base64Image, pledgeUUID, uuid);
    });
    Land.update({
      id: communityId
    }, {
      $push: {
        'pledges': {
          id: pledgeUUID,
          title: data.title,
          description: data.description,
          goal: Number(data.goal),
          amount: 0,
          number: 0,
          yearly: data.type === 'yearly',
          geometry: data.geometry,
          createdBy: Meteor.userId(),
          createdByName: Meteor.user().profile.name,
          createdAt: new Date(),
          images: imageUuids
        }
      }
    });
  },
  'editPledge': function editPledge(id, communityId, data, base64Images) {
    //let pledgeUUID =  genUuid()
    var imageUuids = [];
    base64Images.forEach(function (base64Image) {
      var uuid = genUuid();
      imageUuids.push(uuid);
      Meteor.call('uploadImageFileBase64', base64Image, id, uuid);
    });
    Land.update({
      'pledges.id': id
    }, {
      '$set': {
        'pledges.$.title': data.title,
        'pledges.$.description': data.description,
        'pledges.$.images': imageUuids,
        'pledges.$.updatedAt': new Date(),
        'pledges.$.goal': Number(data.goal),
        'pledges.$.yearly': data.type === 'yearly',
        'pledges.$.geometry': data.geometry
      }
    });
  },
  'addAmountPledge': function addAmountPledge(pledgeId, amount) {
    var land = Land.findOne({
      'pledges.id': pledgeId
    });
    Land.update({
      'pledges.id': pledgeId
    }, {
      $inc: {
        'pledges.$.amount': Number(amount),
        'pledges.$.number': 1
      }
    });
  },
  'reportComment': function reportComment(commentId, threadId) {
    var land = Land.findOne({
      'threads.comments.id': commentId
    });
    var thread = land.threads.filter(function (item) {
      return item.id === threadId;
    })[0];
    var comment = thread.comments.filter(function (item) {
      return item.id === commentId;
    })[0];
    comment.flagged = true;
    Land.update({
      'threads.id': threadId
    }, {
      $set: {
        'threads.$.comments': thread.comments
      }
    });
  },
  'deleteComment': function deleteComment(commentId, threadId) {
    var land = Land.findOne({
      'threads.comments.id': commentId
    });
    var thread = land.threads.filter(function (item) {
      return item.id === threadId;
    })[0]; // let comment = thread.comments.filter( item => item.id === commentId)[0]

    var newComments = thread.comments.filter(function (item) {
      return item.id !== commentId;
    });
    Land.update({
      'threads.id': threadId
    }, {
      $set: {
        'threads.$.comments': newComments
      }
    });
  },
  'unReportComment': function unReportComment(commentId, threadId) {
    var land = Land.findOne({
      'threads.comments.id': commentId
    });
    var thread = land.threads.filter(function (item) {
      return item.id === threadId;
    })[0];
    var comment = thread.comments.filter(function (item) {
      return item.id === commentId;
    })[0];
    comment.flagged = false;
    Land.update({
      'threads.id': threadId
    }, {
      $set: {
        'threads.$.comments': thread.comments
      }
    });
  },
  'upVoteComment': function upVoteComment(commentId, threadId) {
    var land = Land.findOne({
      'threads.comments.id': commentId
    });
    var thread = land.threads.filter(function (item) {
      return item.id === threadId;
    })[0];
    var comment = thread.comments.filter(function (item) {
      return item.id === commentId;
    })[0];
    comment.upVotes.push(Meteor.userId());
    comment.downVotes.remove(Meteor.userId());
    Land.update({
      'threads.id': threadId
    }, {
      $set: {
        'threads.$.comments': thread.comments
      }
    });
  },
  'removeUpVoteComment': function removeUpVoteComment(commentId, threadId) {
    var land = Land.findOne({
      'threads.comments.id': commentId
    });
    var thread = land.threads.filter(function (item) {
      return item.id === threadId;
    })[0];
    var comment = thread.comments.filter(function (item) {
      return item.id === commentId;
    })[0];
    comment.upVotes.remove(Meteor.userId());
    Land.update({
      'threads.id': threadId
    }, {
      $set: {
        'threads.$.comments': thread.comments
      }
    });
  },
  'downVoteComment': function downVoteComment(commentId, threadId) {
    var land = Land.findOne({
      'threads.comments.id': commentId
    });
    var thread = land.threads.filter(function (item) {
      return item.id === threadId;
    })[0];
    var comment = thread.comments.filter(function (item) {
      return item.id === commentId;
    })[0];
    comment.downVotes.push(Meteor.userId());
    comment.upVotes.remove(Meteor.userId());
    Land.update({
      'threads.id': threadId
    }, {
      $set: {
        'threads.$.comments': thread.comments
      }
    });
  },
  'removeDownVoteComment': function removeDownVoteComment(commentId, threadId) {
    var land = Land.findOne({
      'threads.comments.id': commentId
    });
    var thread = land.threads.filter(function (item) {
      return item.id === threadId;
    })[0];
    var comment = thread.comments.filter(function (item) {
      return item.id === commentId;
    })[0];
    comment.downVotes.remove(Meteor.userId());
    Land.update({
      'threads.id': threadId
    }, {
      $set: {
        'threads.$.comments': thread.comments
      }
    });
  },
  'addComment': function addComment(threadId, comment) {
    // UUID
    var s = [];
    var hexDigits = '0123456789abcdef';

    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    s[8] = s[13] = s[18] = s[23] = '-';
    var uuid = s.join('');
    Land.update({
      'threads.id': threadId
    }, {
      // $push : { threads: { [day]: { "$[index]": score } } }
      $push: {
        'threads.$.comments': {
          id: uuid,
          text: comment,
          createdBy: Meteor.userId(),
          createdByName: Meteor.user().profile.name,
          createdAt: new Date(),
          upVotes: [],
          downVotes: []
        }
      } // $push: {
      //     'threads.${index}.comments': {
      //         id: uuid,
      //         text:comment,
      //         createdBy: Meteor.userId(),
      //         createdAt: new Date(),
      //     }
      // }

    } // { arrayFilters: [ { index: index } ] },
    );
    /*
            Land.update({
                    'threads.id': threadId
                },
                {
                    // $push : { threads: { [day]: { "$[index]": score } } }
                    $push: {
                        'threads.$[elm].comments': {
                            id: uuid,
                            text:comment,
                            createdBy: Meteor.userId(),
                            createdAt: new Date(),
                        }
                    }
                    // $push: {
                    //     'threads.${index}.comments': {
                    //         id: uuid,
                    //         text:comment,
                    //         createdBy: Meteor.userId(),
                    //         createdAt: new Date(),
                    //     }
                    // }
                },
                {
                    arrayFilters: [
                        {
                            "elm": threadId
                        }
                    ]
                }
                // { arrayFilters: [ { index: index } ] },
            )
    */
  },
  'addVote': function addVote(pollId, value) {
    // UUID
    var s = [];
    var hexDigits = '0123456789abcdef';

    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    s[8] = s[13] = s[18] = s[23] = '-';
    var uuid = s.join('');
    var land = Land.findOne({
      'polls.id': pollId
    });
    var poll = land.polls.filter(function (item) {
      return item.id === pollId;
    })[0];
    var option = poll.options.filter(function (item) {
      return item.value === value;
    })[0];
    option.votes.push({
      id: uuid,
      value: value,
      createdBy: Meteor.userId(),
      createdByName: Meteor.user().profile.name,
      createdAt: new Date()
    });
    Land.update({
      'polls.id': pollId
    }, {
      $set: {
        'polls.$.options': poll.options
      }
    });
  },
  'addVoteOLD': function addVoteOLD(pollId, value) {
    // UUID
    var s = [];
    var hexDigits = '0123456789abcdef';

    for (var i = 0; i < 36; i++) {
      s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
    }

    s[14] = '4'; // bits 12-15 of the time_hi_and_version field to 0010

    s[19] = hexDigits.substr(s[19] & 0x3 | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01

    s[8] = s[13] = s[18] = s[23] = '-';
    var uuid = s.join('');
    Land.update({
      'polls.id': pollId
    }, {
      // $push : { threads: { [day]: { "$[index]": score } } }
      $push: {
        'polls.$.votes': {
          id: uuid,
          value: value,
          createdBy: Meteor.userId(),
          createdByName: Meteor.user().profile.name,
          createdAt: new Date()
        }
      } // $push: {
      //     'threads.${index}.comments': {
      //         id: uuid,
      //         text:comment,
      //         createdBy: Meteor.userId(),
      //         createdAt: new Date(),
      //     }
      // }

    } // { arrayFilters: [ { index: index } ] },
    );
  },
  // 'measure.update' (measureId, title, description) {
  //     console.log('CALL: measure.update START', measureId)
  //     console.log('measureId', measureId)
  //     console.log('title', title)
  //     console.log('description', description)
  //     console.log('user', Meteor.userId() + Meteor.user().emails)
  //     console.log('email', Meteor.user().emails)
  //     let measure = Measures.findOne({ '_id': measureId })
  //     console.log('measure', measure)
  //
  //     check(title, String)
  //     check(description, String)
  //     if (measure.properties.createdBy === Meteor.userId()) {
  //         Measures.update(measureId, {
  //             $set: {
  //                 updatedAt: new Date(),
  //                 'properties.title': title,
  //                 'properties.description': description
  //             }
  //         })
  //     }
  //     console.log('measure.update END', measureId)
  // },
  'user.update': function userUpdate(profile, newUsername) {
    var oldUsername = Meteor.user().emails[0].address; // console.log('oldUsername', oldUsername)
    // console.log('newUsername', newUsername)
    // console.log('test', oldUsername !== newUsername)

    if (oldUsername !== newUsername) {
      // console.log('GGGGGGGGGGGGGGGGGGGGGGOOOD')
      Accounts.addEmail(this.userId, newUsername);
      Accounts.removeEmail(this.userId, oldUsername); // Accounts.setUsername(Meteor.userId(), newUsername)
    }

    Meteor.users.update({
      _id: Meteor.userId()
    }, {
      $set: {
        updatedAt: new Date(),
        'profile.email': newUsername,
        'profile.name': profile.name // 'profile.city': profile.city,
        // 'profile.number': profile.number,
        // 'profile.centerCoordinates': profile.centerCoordinates,
        // 'profile.pdokv2': profile.pdokv2,
        // 'profile.pdokv2_query': profile.pdokv2_query

      }
    });
  },
  'user.updateHome': function userUpdateHome(coordinates) {
    console.log('--- update HOME marker coordinates: ', coordinates);
    Meteor.users.update({
      _id: Meteor.userId()
    }, {
      $set: {
        updatedAt: new Date(),
        'profile.centerCoordinates': coordinates
      }
    });
  },
  // sendTestEmail (to, from, subject, text) {
  sendTestEmail: function sendTestEmail() {
    console.log('sendTestEmail');
    process.env.MAIL_URL = 'smtp://172.17.0.1:25'; // Make sure that all arguments are strings.
    //check([to, from, subject, text], [String]);
    // Let other method calls from the same client start running, without
    // waiting for the email sending to complete.

    this.unblock();
    var to = 'dobbel01@hotmail.com';
    var from = 'meteor@greenmapper.org';
    var subject = 'Test mail send from greenmapper meteor';
    var text = 'Test email text foo bar bla';
    var message;

    try {
      Email.send({
        to: to,
        from: from,
        subject: subject,
        text: text
      }); // to = 'minne@deontwikkelfabriek.nl'
      // Email.send({ to, from, subject, text });
    } catch (err) {
      console.log('CATCH ERRROR'); //console.log(err.message);
      // console.log(err.code);

      var errM = JSON.stringify(err, Object.getOwnPropertyNames(err));
      console.log(errM);
      message = errM;
    }

    return {
      status: 200,
      // message: 'this is a server test mail response :)'
      message: message
    }; // return Email.sendAsync({ to, from, subject, text }).catch(err => {
    //     //
    // });
  },
  sendTestEmail2: function sendTestEmail2() {
    process.env.MAIL_URL = 'smtp://172.17.0.1:587 ';
    console.log('sendTestEmail2'); // Make sure that all arguments are strings.
    //check([to, from, subject, text], [String]);
    // Let other method calls from the same client start running, without
    // waiting for the email sending to complete.

    this.unblock();
    var to = 'dobbel01@hotmail.com';
    var from = 'meteor@greenmapper.org';
    var subject = 'Test mail send from greenmapper meteor 2';
    var text = 'Test email text foo bar bla 2';
    var message;

    try {
      Email.send({
        to: to,
        from: from,
        subject: subject,
        text: text
      }); // to = 'minne@deontwikkelfabriek.nl'
      // Email.send({ to, from, subject, text });
    } catch (err) {
      console.log('CATCH ERRROR2');
      console.log(err.message);
      message = err.message;
    }

    return {
      status: 200,
      // message: 'this is a server test mail response :)'
      message: message
    }; // return Email.sendAsync({ to, from, subject, text }).catch(err => {
    //     //
    // });
  },
  sendTestEmails: function sendTestEmails() {
    process.env.MAIL_URL = 'smtps://172.17.0.1:465 ';
    console.log('sendTestEmail S'); // Make sure that all arguments are strings.
    //check([to, from, subject, text], [String]);
    // Let other method calls from the same client start running, without
    // waiting for the email sending to complete.

    this.unblock();
    var to = 'dobbel01@hotmail.com';
    var from = 'meteor@greenmapper.org';
    var subject = 'Test mail send from greenmapper meteor S';
    var text = 'Test email text foo bar bla S';
    var message;

    try {
      Email.send({
        to: to,
        from: from,
        subject: subject,
        text: text
      }); // to = 'minne@deontwikkelfabriek.nl'
      // Email.send({ to, from, subject, text });
    } catch (err) {
      console.log('CATCH ERRROR2');
      console.log(err.message);
      message = err.message;
    }

    return {
      status: 200,
      // message: 'this is a server test mail response :)'
      message: message
    }; // return Email.sendAsync({ to, from, subject, text }).catch(err => {
    //     //
    // });
  },
  'error.gen': function errorGen(message) {
    console.log('ERROR server');
    var a = 1 / 0;
    console.log(a); // Meteor.call('error.gen')

    throw new Error('Meteorr SERVER test error: ' + new Date().getTime());
  },
  'log': function log(message) {
    console.log('HALLO2');
    console.log(message);
  },

  /**
   * upload base64 image method
   *
   * @param {*} file
   *
   * @param {string}     file.userid
   * @param {string}     file.type
   * @param {number}     file.size
   * @param {string}     file.name
   * @param {string}     file.extension - file extension
   * @param {string}     file.content   - base64 string
   */
  uploadImageFileBase64: function uploadImageFileBase64(base64Image, threadUUID, fileUUID) {
    console.log('uploadImageFileBase64 CALLEDd MVD'); // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
    // console.log(base64regex.test('SomeStringObviouslyNotBase64Encoded...'))
    // console.log(base64regex.test('U29tZVN0cmluZ09idmlvdXNseU5vdEJhc2U2NEVuY29kZWQ='))

    var file = {
      content: base64Image,
      userid: threadUUID,
      //Meteor.userId(),
      extension: '.jpg',
      name: 'test',
      measureid: fileUUID
    }; // console.log('content', base64Image.test(file.content))
    // console.log(file)
    // if (Meteor.userId()) {

    var date = new Date();
    var time = date.toLocaleDateString() + ' ' + date.toLocaleTimeString(); // Meteor.http.post(Meteor.settings.public.uploadImgUrl, { params: file },
    // HTTP.call('POST', 'http://217.21.192.155/beeapp/php/services/img.upload.php', { params: file },
    // HTTP.call('POST', 'https://cdnbij.deontwikkelfabriek.nl/php/services/img.upload.php', { params: file },
    // HTTP.call('POST', Meteor.settings.public.uploadImgUrl, { params: file },

    HTTP.call('POST', 'https://cdn.greenmapper.org/php/services/img.upload.php' + '', {
      params: file
    }, // HTTP.call('POST', 'http://ptsv2.com/t/beeapp/post', { params: file },
    Meteor.bindEnvironment(function (error, response, body) {
      // console.log(response)
      if (!error && response.statusCode === 200) {// console.log("AAP")
        // console.log(response)

        /*
        console.log("JSON PARSE ERROR ")
        var content = JSON.parse(response)
        console.log("KOE")
        console.log(content)
         if (content.status === 200) {
            // console.log('uploadImageFileBase64: Image uploaded')
            // var id = Images.insert({
            //     measureId: measureId,
            //     userid: Meteor.userId(),
            //     // name: content.name,
            //     // originalName: file.name.substring(0, file.name.lastIndexOf('.')),
            //     // originalFile: file.name,
            //     file: content.file,
            //     ext: content.ext,
            //     original: '/images/original/' + content.userid + '/' + content.name + '.' + content.ext,
            //     medium: '/images/medium/' + content.userid + '/' + content.name + '.' + content.ext,
            //     thumb: '/images/thumb/' + content.userid + '/' + content.name + '.' + content.ext,
            //     square: '/images/square/' + content.userid + '/' + content.name + '.' + content.ext
            // })
            // console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time)
            // console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time)
            // let imagename = content.name + '.' + content.ext
            // Measures.update({ _id: measureId }, { $set: { 'properties.photo': imagename } })
            // console.log('imagename: ' + imagename)
        } else {
            console.log("Z")
            console.log(content.status)
            console.log(typeof (content.status))
            console.log('\x1b[31m%s\x1b[0m', response.content)
        }*/
      } else {
        console.log('Y');
        console.log('\x1b[31m%s\x1b[0m', 'Exception url handler [' + Meteor.settings.public.uploadImgUrl + '] - ' + time);
      }
    }, function (e) {
      console.log('Q');
      console.log('\x1b[31m%s\x1b[0m', e);
    })); // } else {
    //     return {
    //         status: 530,
    //         message: 'Not logged in'
    //     }
    // }
  },

  /**
   * upload base64 image method
   *
   * @param {*} file
   *
   * @param {string}     file.userid
   * @param {string}     file.type
   * @param {number}     file.size
   * @param {string}     file.name
   * @param {string}     file.extension - file extension
   * @param {string}     file.content   - base64 string
   */
  uploadImageFileBase64OLD: function uploadImageFileBase64OLD(base64Image, measureId) {
    console.log('uploadImageFileBase64 CALLEDd MVD'); // var base64regex = /^([0-9a-zA-Z+/]{4})*(([0-9a-zA-Z+/]{2}==)|([0-9a-zA-Z+/]{3}=))?$/
    // console.log(base64regex.test('SomeStringObviouslyNotBase64Encoded...'))
    // console.log(base64regex.test('U29tZVN0cmluZ09idmlvdXNseU5vdEJhc2U2NEVuY29kZWQ='))

    var file = {
      content: base64Image,
      userid: Meteor.userId(),
      extension: 'jpg',
      measureid: measureId
    }; // console.log(base64regex.test(file.content))
    // console.log(file)

    console.log('---------------------V=1--------------'); // if (Meteor.userId()) {

    var date = new Date();
    var time = date.toLocaleDateString() + ' ' + date.toLocaleTimeString(); // Meteor.http.post(Meteor.settings.public.uploadImgUrl, { params: file },
    // HTTP.call('POST', 'http://217.21.192.155/beeapp/php/services/img.upload.php', { params: file },
    // HTTP.call('POST', 'https://cdnbij.deontwikkelfabriek.nl/php/services/img.upload.php', { params: file },

    HTTP.call('POST', Meteor.settings.public.uploadImgUrl, {
      params: file
    }, // HTTP.call('POST', 'http://ptsv2.com/t/beeapp/post', { params: file },
    Meteor.bindEnvironment(function (error, response, body) {
      console.log(response);

      if (!error && response.statusCode === 200) {
        var content = JSON.parse(response.content);

        if (content.status === 200) {
          var id = Images.insert({
            measureId: measureId,
            userid: Meteor.userId(),
            // name: content.name,
            // originalName: file.name.substring(0, file.name.lastIndexOf('.')),
            // originalFile: file.name,
            file: content.file,
            ext: content.ext,
            original: '/images/original/' + content.userid + '/' + content.name + '.' + content.ext,
            medium: '/images/medium/' + content.userid + '/' + content.name + '.' + content.ext,
            thumb: '/images/thumb/' + content.userid + '/' + content.name + '.' + content.ext,
            square: '/images/square/' + content.userid + '/' + content.name + '.' + content.ext
          });
          console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time); // console.log('\x1b[32m%s\x1b[0m', 'SAVED image [id: ' + id + ', name:' + content.name + ', file:' + content.file + ', userid: ' + content.userid + '] - ' + time)
          // let imagename = content.name + '.' + content.ext
          // Measures.update({ _id: measureId }, { $set: { 'properties.photo': imagename } })
          // console.log('imagename: ' + imagename)
        } else {
          console.log(content.status);
          console.log(_typeof(content.status));
          console.log('\x1b[31m%s\x1b[0m', response.content);
        }
      } else {
        console.log('\x1b[31m%s\x1b[0m', 'Exception url handler [' + Meteor.settings.public.uploadImgUrl + '] - ' + time);
      }
    }, function (e) {
      console.log('\x1b[31m%s\x1b[0m', e);
    })); // } else {
    //     return {
    //         status: 530,
    //         message: 'Not logged in'
    //     }
    // }
  },
  sendEmail: function sendEmail(to, from, subject, text) {
    // Make sure that all arguments are strings.
    check([to, from, subject, text], [String]); // Let other method calls from the same client start running, without
    // waiting for the email sending to complete.

    this.unblock();
    console.log('EMAIL SUBJECT');
    console.log(subject);
    console.log('EMAIL TEXT');
    console.log(text);
    Email.send({
      to: to,
      from: from,
      subject: subject,
      text: text
    });
  }
});