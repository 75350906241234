export default {
  messages: {
    // account_created: 'Please check your email to verify your registration. This sometimes can take up to 10 minutes. Please check the spam folder if the email has not been received.',
    // password_forgot: 'Please check your email to reset your password.',
    // verification_success: 'Your e-mail has been verified. You can now login.',
    // verification_failed: 'Verification has failed.',
    // password_recover_success: 'Your password has been reset. You can now login with the new password.',
    // logout_confirmation: 'Are you sure you want to log out?',
    // confirm: 'Confirm',
    // update_available: 'A new update is available. Click on ok to reload the page.',
    // account_signed_out: 'You have been signed out',
    // account_updated: 'Account succesfully updated'
    account_created: 'Account successfully created',
    password_forgot: 'Please check your email to reset your password.',
    account_signed_out: 'You have been signed out',
    account_updated: 'Account succesfully updated',
    verification_success: 'Your e-mail has been verified. You can now login.',
    verification_failed: 'Verification has failed.',
    password_recover_success: 'Your password has been reset. You can now login with the new password.',
    logout_confirmation: 'Are you sure you want to log out?',
    login_confirmation: 'You have succesfully signed in. Welcome back!',
    login_failed: 'Email and password combination not recognised',
    confirm: 'Confirm',
    update_available: 'A new update is available. Click on ok to reload the page.',
    url_copied_to_clipboard: 'The map url has been copied to clipboard',
    add_area_account_required: 'To add a new area you\'re required to have an account.',
    area_deleted: 'Area has been removed',
    area_updated: 'Area has been updated',
    unsubscribed: 'You have been unsubcribed from the community',
    subscribed: 'You are now part of this community',
    thread_created: 'A new thread has been created',
    thread_updated: 'Discussion has been updated',
    comment_created: 'You have posted a reply',
    comment_reported: 'This comment has been flagged for moderation',
    comment_unreported: 'This comment has been cleared by moderation',
    comment_deleted: 'This comment has been deleted',
    community_updated: 'This community has been updated',
    RMS_send: 'Moderator request has been sent',
    poll_created: 'Poll created',
    polis_created: 'Polis created',
    meeting_created: 'Meeting created',
    meeting_invite_accepted: 'You have accepted the invite',
    meeting_invite_declined: 'You have declined the invite'
  },
  errors: {
    general_error: 'An error occured. Please try again.',
    not_found: 'Sorry, nothing here...',
    network: 'Network error. Please check your internet connection',
    // fields: 'Please correct the input fields',
    required: 'Required field',
    register: {
      password_length: 'The minimum length of the password is {length} characters.',
      password_match: 'Passwords do not match.',
      email_valid: 'A valid email address is required',
      name_length: 'Display name length between {minLength} and {maxLength} characters ',
      organization_length: 'Organization name length between {minLength} and {maxLength} characters '
    }
  },
  buttons: {
    pledge: 'Pledge',
    submit: 'Submit',
    logout: 'Logout',
    cancel: 'Cancel',
    login: 'Login',
    register: 'Submit',
    // go_back: 'Go back',
    send: 'Send',
    reSend: 'Resend',
    close: 'Close',
    // previous: 'Previous',
    save: 'Save',
    create: 'Create',
    update: 'Update',
    delete: 'Remove',
    subscribe: 'Subscribe',
    unsubscribe: 'Unsubscribe',
    next: 'Next',
    continue: 'Continue',
    back: 'Back',
    add: 'Add',
    edit: 'Edit',
    reply: 'Reply',
    report: 'Report',
    unreport: 'Unreport',
    goto_authenticate: 'To sign in',
    stop: 'Stop',
    continue_register: 'Continue registration',
    continue_participation: 'Continue participation',
    become_moderator: 'Become moderator',
    add_image: 'Add image',
    accept: 'Accept',
    decline: 'Decline'
  },
  headers: {
    login: 'Login',
    register: 'Register',
    password_forgot: 'Request a new password',
    password_recover: 'Reset password',
    administrator: 'Administrator',
    navigation: 'Navigation',
    my_overview: 'My overview',
    overview: 'Overview'
  },
  activities: {
    act_cycl: {
      title: 'Cycling',
      desciption: 'TODO'
    },
    act_walk: {
      title: 'Walking or running',
      desciption: 'TODO'
    },
    act_tour: {
      title: 'Touring by car or motor',
      desciption: 'TODO'
    },
    act_natu: {
      title: 'Watching nature',
      desciption: 'TODO'
    },
    act_water: {
      title: 'Water sports',
      desciption: 'TODO'
    },
    act_play: {
      title: 'Playing or sitting',
      desciption: 'TODO'
    },
    act_wintr: {
      title: 'Winter sports',
      desciption: 'TODO'
    },
    act_mount: {
      title: 'Mountain sports',
      desciption: 'TODO'
    }
  },
  labels: {
    account_required: 'Account required',
    read_more: 'read more',
    'area_create_button': 'Save',
    'area_update_button': 'Save',
    'area_created_succes': 'Area is succesfully created',
    'area_updated_succes': 'Area is succesfully updated',
    'community_created_succes': 'Community is succesfully created',
    agree_disclaimer: 'I agree with these terms',
    my_account: 'My account',
    register: 'Create account',
    logout: 'Sign out',
    login: 'Sign in',
    email: 'Email address',
    name: 'Display name',
    companyName: 'Company name',
    organization: 'Organization name',
    sector: 'Sector',
    address: 'Address',
    address_search: 'Address',
    // zipcodeCompany: 'Zipcode headquarter',
    website: 'Webpage',
    city: 'City',
    // cityCompany: 'City headquarter',
    age: 'Age',
    education: 'Education',
    gender: 'Gender',
    type: 'Type',
    password: 'Password',
    repeat_password: 'Repeat password',
    remember_me: 'Remember me',
    password_forgot: 'Forgot your password?',
    home: 'Home',
    here: 'here',
    neighbourhood: 'neighbourhood',
    'living-area': 'living-area',
    country: 'country',
    worldwide: 'worldwide',
    share_this_area: 'Share this area',
    share_this_community: 'Share this community',
    subscribed: 'Subscribed',
    reply: 'Reply',
    comment: 'Comment',
    finish: 'Finish',
    readmore: 'read more',
    search: 'search',
    created_on: 'created_on'
  },
  pages: {
    moderators: {
      title: 'Moderators',
      mod_requests: 'Moderator requests',
      mods: 'Moderators'
    },
    create_community: {
      title: 'Create community'
    },
    land_owner_register: {
      title: 'Land owner registration',
      steps: {
        general: {
          nav_title: ' General info',
          title: 'Land owner information',
          task_title: 'Proveide general information for the land you own',
          email: 'Email'
        },
        draw: {
          nav_title: 'Map',
          title: 'Draw area',
          task_title: 'Draw your area'
        },
        description: {
          nav_title: 'Description',
          title: 'Describe your community',
          task_title: 'Provide a title that represents your community',
          input_title: 'Titel',
          input_title_error: 'Maximum of 50 characters',
          input_description: 'Description',
          task_description: 'Describe what makes this particular community attractive, valuable or important',
          input_description_error: 'Maximum of 1000 characters'
        },
        finalize: {
          nav_title: 'Finalize',
          title: 'Information',
          task_title: 'Thank you for supplying this information, if you click next we will finalize your community. To discuss topics with your community you can open a thread. Click on the 3 dots to the right of \'threads\' to add a new thread and discuss a new topic.'
        }
      }
    },
    add_pledge: {
      title_nav: 'Add Pledge',
      title: 'Pledge amount',
      description: 'todo text',
      amount: 'Amount'
    },
    create_thread: {
      title_nav: 'Create discussion',
      title_nav_edit: 'Edit discussion',
      title: 'Provide a title',
      description: 'Provide a first post',
      options: 'Add poll options',
      option: 'option',
      upload: 'Upload photos (.jpg)'
    },
    create_poll: {
      title_nav: 'Create Poll',
      title_nav_edit: 'Edit Poll',
      title: 'Provide a title',
      description: 'Provide a first post',
      upload: 'Upload photos (.jpg)'
    },
    create_polis: {
      title_nav: 'Create Polis',
      title_nav_edit: 'Edit Polis',
      title: 'Provide a title',
      id: 'Provide a polis ID',
      voter_option: 'Only subscribed users can vote'
    },
    create_meeting: {
      title_nav: 'Create Polis',
      title_nav_edit: 'Edit Polis',
      title: 'Provide a title',
      description: 'Provide a description',
      url: 'Provide external url of meeting',
      dateTime: 'Start time & date'
    },
    meeting: {
      total_subs: 'Total subscribers',
      accepted_invites: 'Accepted Invites',
      rejected_invites: 'Rejected Invites',
      planned_label: 'planned on',
      invited_banner: 'You have been invited for the meeting',
      accepted_banner: 'You have accepted the invite, if you are selected you will be emailed with further details about the meeting.',
      rejected_banner: 'You have declined the invite for the meeting',
      send_banner: 'Send the link of the meeting to subscribers that have accepted the invite.',
      resend_banner: 'Resend the link of the meeting.  Waring you have already send the links!'
    },
    landing: {
      my_portal: 'My Xena'
    },
    welcome: {
      title: 'Welcome to Greenmapper Friends',
      introduction: 'Introduction',
      paragraph_1: 'In Greenmapper Friends you can mark attractive, valuable or for you important natural places. These areas have to be places with greenery, water or nature. You can think of a place in a park, at a lake, at sea, in a forest, a meadow, a field of flowers, a spot to watch birds etc. It can be a place within or outside a city or town, small or large. You may visit them often or never! As long as it is a natural place that you find attractive or valuable or is meaningful for you.',
      subtitle_2: 'Why register?',
      paragraph_2: 'The idea is that you enter your favourite natural areas that you want to be a friend of. For that reason we ask you a couple of questions to create an account for you. Your emailaddress is not shown in the app, only your (screen)name.',
      subtitle_3: 'Privacy policy',
      paragraph_3: 'Greenmapper Friends is an initiative of the University of Groningen. Greenmapper Friends stores your emailaddress in the Greenmapper Landscape Friend Registry (GLFR). No one can access this email accept the Greenmapper organisation. You can via the GLFR be reached anonymously by land-owners, land management, or other friends of the area. But these third parties do not have direct access to your email. We follow the European General Data Protection Regulation guidelines for privacy and the storage of your personal data.',
      continue_without_account: 'Continue without account',
      login_text: 'If you already have an account, you can sign in',
      forgot_password: 'Forgot password?',
      forgot_password_dialog: 'Please enter your email address.'
    },
    introduction: {
      title: 'Introduction',
      text: 'In Greenmapper Friends you can mark attractive, valuable or for you important natural places. These areas have to be places with greenery, water or nature. You can think of a place in a park, at a lake, at sea, in a forest, a meadow, a field of flowers, a spot to watch birds etc. It can be a place within or outside a city or town, small or large. You may visit them often or never! As long as it is a natural place that you find attractive or valuable or is meaningful for you.',
      start_btn: 'Start survey'
    },
    register: {
      disclaimer_title: 'Disclaimer Greenmapper Friends',
      disclaimer: 'Greenmapper Friends stores your email address in the Greenmapper Landscape Friend Registry (GLFR). No one can access this email accept Greenmapper Friends. You can via the GLFR be reached anonymously by land-owners, land management, or other friends of the area. But these third parties do not have direct access to your email or personal data. We also aks your home location. We only do this to show you a map of your surroundings by default. We follow the European General Data Protection Regulation guidelines for privacy and the storage of your personal data. Read more details'
    },
    info_contact: {
      title: 'About',
      contact: 'Contact',
      disclaimer_title: 'Disclaimer',
      implementation: 'Implementation: '
    },
    map: {
      title: 'Overall community map',
      add_btn: 'Draw a new area',
      share_title: 'Share map url',
      share_btn: 'Copy to clipboard',
      hint_on_add: 'Place the dot on the map to start drawing',
      hint_on_end_draw: 'You can still modify the area.',
      zoom_to_area_btn: 'Zoom to area',
      details_btn: 'View details' // share_desc: 'Share'

    },
    timeline: {
      title: 'Timeline',
      hint: 'Did you know that the most popular activity in favourite natural places is walking?',
      rating: 'Rating',
      rating_subtext: 'Personal rating of the marked area',
      visit_frequency: 'Visit frequency',
      visit_frequency_subtext: 'How often the area is visited',
      activities: 'Activities',
      activities_subtext: 'Activities reported at marked area',
      add_btn: 'Add an area',
      edit_btn: 'Edit area',
      delete_btn: 'Remove area',
      delete_title: 'Remove area',
      delete_subtitle: 'Are your sure?'
    },
    add_area_info: {
      title: 'Add area',
      add_btn: 'Add an area'
    },
    my_areas: {
      title: 'My areas',
      sub_title: 'All the nature related places I find attractive, valuable or important.',
      created_areas: 'Created areas',
      joint_surface: 'Joint surface (km2)'
    },
    my_communities: {
      title: 'My communities',
      sub_title: 'All the areas of land owners or land management that overlap with my areas. Here I can be reached as a friend of those areas.',
      delete_btn: 'Unsubscribe',
      delete_title: 'Unsubscribe',
      delete_subtitle: 'Are you sure you want to unsubscribe from this community?',
      befriended_communities: 'Befriended communities',
      joint_surface: 'Joint surface (km2)'
    },
    info: {
      text1: 'Greenmapper Friends is developed by Greenmapper. For more information please have a look at the Greenmapper',
      text2: 'If you have any questions or you want to contact us, please drop us an email at:',
      text3: 'With Greenmapper Friends we try to keep the  application and data as accurate and free of errors as possible. Should the app -despite our efforts- contain incomplete or inacurate information, then we cannot accept responsibility for that.',
      text4: 'We reserve the right to change, remove or add material without any prior notice. Greenmapper Friends  accepts no liability for any information contained on websites to which we refer via hyperlinks.'
    },
    my_owned_communities: {
      title: 'My communities',
      owned_communities: 'Owned communities',
      total_subs: 'Total subscribers',
      joint_surface: 'Joint surface (km2)'
    },
    community: {
      report_title: 'Report comment',
      report_subtitle: 'Are you sure you want to report this comment for moderation?',
      delete_title: 'Delete comment',
      delete_subtitle: 'Are you sure you want to delete this comment?',
      discussions_title: 'Discussions',
      discussions_subtitle: 'Open discussion on any topic',
      poll_title: 'Poll',
      poll_subtitle: 'Simple polling and voting for options',
      polis_title: 'Polis',
      polis_subtitle: 'Advanced large scale interactive statement polling via',
      pledges_title: 'Pledges',
      pledges_subtitle: 'Virtually pleding of funds for biodiversity, landscape quality or sustainable services',
      meetings_title: 'Meetings',
      meetings_subtitle: 'In-depth online workshop or council of friends',
      RMS_dialog_title: 'Request moderator status',
      RMS_dialog_text: 'Do you want to send a request to become moderator for this community?'
    },
    account: {
      edit_title: 'Edit profile',
      edit_text: 'Edit your current profile information',
      edit_btn: 'Edit profile',
      signout_title: 'Sign out',
      signout_text: 'Sign out of your account',
      signout_btn: 'Sign out'
    },
    create_area: {
      title: 'Create area',
      home_title: 'Place home marker',
      stop_title: 'Stop registration and finish later',
      stepper: {
        description: 'Description',
        rate: 'Rating',
        visit_freq: 'Visiting frequency',
        activities: 'Activities',
        connected: 'Online connected'
      },
      steps: {
        level: {
          nav_title: 'Level',
          title: 'Choose level',
          task_title: 'select your level of area',
          options: {
            neighborhood: 'neighborhood / local',
            living_area: 'living area / regional',
            country: 'country',
            world: 'world'
          }
        },
        map: {
          nav_title: 'Map',
          title: 'Draw area',
          task_title: 'Draw your area'
        },
        description: {
          nav_title: 'Describe',
          title: 'Describe your area',
          task_title: 'Provide a title that represents your area',
          input_title: 'Titel',
          input_title_error: 'Maximum of 50 characters',
          input_description: 'Description',
          task_description: 'State in your own words what makes this particular area attractive, valuable or important to you',
          input_description_error: 'Maximum of 1000 characters'
        },
        rate: {
          nav_title: 'Rating',
          title: 'Rate your area',
          task_title: '(Score: 1 = bad, 6 = average, 10 = perfect)'
        },
        visit_freq: {
          nav_title: 'Visit',
          title: 'How often do you vistit your area?',
          options: {
            daily: 'Daily',
            weekly: 'Weekly',
            monthly: 'Monthly',
            few_times_a_year: 'Few times a year',
            yearly: 'Yearly',
            rarely: 'Rarely',
            never: 'Never'
          }
        },
        activities: {
          nav_title: 'Activities',
          title: 'What activities do you engage in at this area?'
        },
        priority: {
          nav_title: 'Priority',
          title: 'Should this place be:',
          options: {
            maintained: 'Maintained (= keep it as it is)',
            strengthened: 'Strengthened (= improve its qualities)'
          }
        },
        relationship: {
          nav_title: 'Relation',
          title: 'What is your relationship to this area',
          options: {
            tourist: 'Tourist',
            inhabitant: 'Inhabitant',
            work: 'Work-related'
          }
        }
      },
      _edit: {
        title: 'Update area'
      }
    },
    edit_area: {
      title: 'Update area'
    },
    funding: {
      title: 'Pledge funds',
      nav_intro: 'Introduction',
      nav_amount: 'Amount',
      nav_locations: 'Locations',
      nav_objectives: 'Objectives',
      nav_finalize: 'Finalize',
      intro1: 'Dear participant,',
      intro2: 'Thank you for taking part in our Greenmapper experiment.',
      intro3: 'You are a fan of different areas. Sustainable development of these areas may require extra financial support. Especially it may be needed to supply longer-term funding for biodiversity conservation, for attractive landscape scenery, and making business activities in your favorite area sustainable. In this part of the Greenmapper Friends experiment we would like to know about your general preferences for giving financial support.',
      intro4: 'We are curious to know how you would divide a budget between your areas, whether you would be more likely to support biodiversity, landscape scenery or sustainable business.',
      intro5: 'It takes approximately 5-8 minutes to complete the experiment, and your responses will be treated anonymously. Take your time answering the questions and be honest. There are no right or wrong answers. Your anonymized answers are valuable for academic research.\n' + 'You may choose to exclude your data without any consequences. For further questions, you can contact me via email (j.beverdam@rug.nl). By participating in this study on nature funding, you consent to the processing of your anonymized personal data for research purposes.',
      amount1: 'Virtual funding amount',
      amount2: 'To start this we first ask you to think of a possible budget that you feel you might be willing to spend. Please note that this is completely virtual and hypothetical but on the other hand we would like it to be as realistic as possible.',
      amount3: 'Choose a virtual but somewhat realistic yearly budget.',
      amount4: 'Would you please elaborate on the virtual amount you have chosen? Why did you choose this amount?',
      amount5: 'yearly budget',
      max: 'Maximun amount 1000 euro',
      min: 'Minimun amount 1 euro',
      locations1: 'Budget allocation between locations',
      locations2: 'Please indicate how you would divide the budget among your areas',
      locations3: 'Please elaborate on the allocation of your chosen funding with respect to your favorite natural areas. Why did you choose the allocation you did?',
      objectives1: 'Budget allocation between funding objectives',
      objectives2: 'This allocation is shown here per area. For every area (local, regional, national, global), please indicate how you would like to divide the allocated budget over the three funding objectives; biodiversity conservation; attractive landscape scenery and making business activities in your favorite area sustainable.',
      objectives3: 'Would you please elaborate on the allocation of your chosen funding with respect to the three funding objectives? Why did you choose the allocation you did?',
      biodiversity_title: 'Biodiversity conservation',
      biodiversity_text: 'A rich biodiversity supports ecosystem functioning and is crucial for supporting all life on earth. Biodiversity conservation projects aim to enhance or conserve biodiversity values, often in a local context. Such projects can relate to farmer (and civilian) collective action, where the collective aims to improve upon soil and water quality multiple hectares of agricultural land. A biodiversity conservation project could also relate to organizations renting out holiday bungalows, the organization requiring funding for nature-inclusive land management on their owned land, so that biodiversity can be improved upon.',
      ALC_title: 'Attractive landscape scenery',
      ALC_text: 'Landscape scenery, the nice view of a landscape with for instance a combination of cows in a meadow, a forest nearby and a distant urban skyline is an important recreational aspect of natural areas. Landscape scenery projects might relate to taking inventory of the quantity and state of willow trees in a certain area, and the subsequent maintenance required. A landscape scenery project might also refer to the creation of lakes with surrounding forests on a certain plot of land.',
      SUSBUS_title: 'Sustainable busines',
      SUSBUS_text: 'Sustainable businesses engage in their economic activities without generating negative effects for their (local) environment. Sustainable business projects might relate to the transitioning of a conventional local farm to an organic farm. It can also refer to the creation of a network of local firms and organizations, with the aim of reducing waste and pollution in an integrated manner.',
      finalize1: 'Thank you very much for your participation in this study on the financing of nature, conducted by the University of Groningen. Your answers are extremely valuable to us. Please click on the ‘save’ button to record your responses.',
      finalize2: 'Please be reminded that it is possible to retract your submitted data without any consequences. If you wish to do so, or have any other questions regarding the research, please send an e-mail to'
    }
  },
  drawer: {
    area_section: 'Discover',
    my_section: 'My overview',
    other_section: 'Other'
  },
  gender: {
    male: 'Male',
    female: 'Female',
    other: 'Other'
  },
  education: {
    1: 'Less than highschool',
    2: 'High school graduate',
    3: 'Some college,no degree',
    4: 'Associate\'s degree',
    5: 'Bachelors degree',
    6: 'Master\'s degree',
    7: 'Professional school degree',
    8: 'Doctorate degree'
  }
};